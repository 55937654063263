import React, { Suspense, lazy } from "react";
import { Switch, Route } from "react-router-dom";

import RouteLoading from "./RouteLoading";

const WebRouter = lazy(() => import("./web/WebRouter"));
const SteypTermsAndCondition = lazy(() =>
    import("../learn/screens/terms&condition/SteypTermsAndCondition")
);

class AppRouter extends React.PureComponent {
    render() {
        return (
            <Suspense fallback={<RouteLoading />}>
                <Switch>
                    <Route
                        exact
                        path="/tos/"
                        component={SteypTermsAndCondition}
                    />
                    {/* Website Pages */}
                    <Route path="/" component={WebRouter} />
                </Switch>
            </Suspense>
        );
    }
}

export default AppRouter;
