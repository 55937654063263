import { createStore } from "redux";
import colors from "./Colors";

const initialState = {
    profile_menu_right: "-400px",
    menu_type: "normal",
    divMainClass: "responsive",
    active_menu: "dashboard",
    tech_schooling__active_menu: "dashboard",
    active_profile_menu: "",
    respSideMenuClass: "",
    respSearch: false,
    isSignupUser: false,
    user_data: {},
    userSubscriptionType: "",
    campus_data: {},
    signup_data: {},
    isUserDataLoading: false,
    user_profile: {},
    rm_session: {},
    designation: {},
    nextPath: "/tech-schooling/",

    notifications: [],
    notifications_count: 0,

    message: {},
    isStudentUploadModal: false,
    isInvitationModal: false,
    is_validationModal: false,
    // Payment Link
    isPaymentLinkModal: false,
    payment_plan: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "TOGGLE_VALIDATION_MODAL":
            return {
                ...state,
                is_validationModal: !state.is_validationModal,
            };
        case "TOGGLE_PAYMENT_LINK_MODAL":
            return {
                ...state,
                isPaymentLinkModal: !state.isPaymentLinkModal,
            };

        case "UPDATE_PAYMENT_LINK_MODAL":
            return {
                ...state,
                payment_plan: action.payment_plan,
            };
        case "UPDATE_SUBSCRIPTION_TYPE":
            return {
                ...state,
                userSubscriptionType: action.userSubscriptionType,
            };
        case "TOGGLE_PROFILE_MENU":
            if (state.profile_menu_right === 0) {
                return {
                    ...state,
                    profile_menu_right: "-400px",
                };
            } else {
                return {
                    ...state,
                    profile_menu_right: 0,
                };
            }
        case "TOGGLE_STUDENT_UPLOAD_MODAL":
            return {
                ...state,
                isStudentUploadModal: !state.isStudentUploadModal,
            };

        case "TOGGLE_INVITATION_MODAL":
            return {
                ...state,
                isInvitationModal: !state.isInvitationModal,
            };
        case "TOGGLE_SIGNUP_USER":
            return {
                ...state,
                isSignupUser: action.bool,
            };
        case "MENU_TYPE":
            if (action.menu_type === "normal") {
                return {
                    ...state,
                    menu_type: action.menu_type,
                    divMainClass: "",
                };
            } else if (action.menu_type === "hidden") {
                return {
                    ...state,
                    menu_type: action.menu_type,
                    divMainClass: "responsive-mini",
                };
            } else {
                return {
                    ...state,
                    menu_type: action.menu_type,
                    divMainClass: "responsive",
                };
            }

        case "TOGGLE_RESP_SIDE_MENU":
            if (action.value === "hide") {
                return {
                    ...state,
                    respSideMenuClass: "",
                };
            }
            if (state.respSideMenuClass === "") {
                return {
                    ...state,
                    respSideMenuClass: "show",
                };
            } else {
                return {
                    ...state,
                    respSideMenuClass: "",
                };
            }

        case "SET_MENU_ICON_REF":
            return {
                ...state,
                menuIconRef: action.ref,
            };

        case "ACTIVE_MENU":
            return {
                ...state,
                active_menu: action.active_menu,
            };

        case "TECH_SCHOOL_ACTIVE_MENU":
            return {
                ...state,
                tech_schooling__active_menu: action.tech_schooling__active_menu,
            };

        case "ACTIVE_PROFILE_MENU":
            return {
                ...state,
                active_profile_menu: action.active_profile_menu,
            };
        case "UPDATE_DESIGNATION":
            return {
                ...state,
                designation: action.designation,
            };

        case "UPDATE_PRACTICE_FILTER":
            return {
                ...state,
                practiceFilter: action.practiceFilter,
            };

        case "UPDATE_LESSONS":
            return {
                ...state,
                lessons: action.lessons,
            };
        case "TOGGLE_LESSONS_LOADING": {
            return {
                ...state,
                isLessonsLoading: !state.isLessonsLoading,
            };
        }
        case "TOGGLE_TOPICS_LOADING": {
            return {
                ...state,
                isTopicsLoading: !state.isTopicsLoading,
            };
        }

        case "UPDATE_TOPICS":
            return {
                ...state,
                topics: action.topics,
            };

        //Update freeSkills, freeDesignations, freeLessons, freeTopics
        case "UPDATE_FREE_DESIGNATION":
            return {
                ...state,
                freeDesignation: action.freeDesignation,
            };
        case "TOGGLE_FREE_DESIGNATIONS_LOADING":
            return {
                ...state,
                isFreeDesignationsLoading: !state.isFreeDesignationsLoading,
            };

        case "UPDATE_FREE_SKILLS":
            return {
                ...state,
                freeSkills: action.freeSkills,
            };

        case "TOGGLE_FREE_SKILLS_LOADING":
            return {
                ...state,
                isFreeSkillsLoading: !state.isFreeSkillsLoading,
            };

        case "UPDATE_FREE_LESSONS":
            return {
                ...state,
                freeLessons: action.freeLessons,
            };
        //Challenge page
        // case "UPDATE_CHALLENGES":
        //     return {
        //         ...state,
        //         challenges: action.challenges,
        //     };
        case "UPDATE_SUBSCRIPTION_DISCOUNT_STATUS": {
            return {
                ...state,
                subscriptionDiscountStatus: action.subscriptionDiscountStatus,
            };
        }
        case "UPDATE_USER_DATA":
            localStorage.setItem("user_data", JSON.stringify(action.user_data));
            return {
                ...state,
                user_data: action.user_data,
            };

        case "UPDATE_CAMPUS_DATA":
            localStorage.setItem(
                "campus_data",
                JSON.stringify(action.campus_data)
            );
            return {
                ...state,
                campus_data: action.campus_data,
            };

        case "UPDATE_SIGNUP_DATA":
            localStorage.setItem(
                "signup_data",
                JSON.stringify(action.signup_data)
            );
            return {
                ...state,
                signup_data: action.signup_data,
            };

        case "UPDATE_USER_PROFILE":
            return {
                ...state,
                user_profile: action.user_profile,
            };
        case "TOGGLE_PROFILE_LOADING":
            return {
                ...state,
                isUserDataLoading: !state.isUserDataLoading,
            };
        case "UPDATE_NEXT_PATH":
            return {
                ...state,
                nextPath: action.nextPath,
            };

        case "TOGGLE_RESP_SEARCH":
            if (action.respSearch) {
                return {
                    ...state,
                    respSearch: action.respSearch,
                };
            }

            return {
                ...state,
                respSearch: !state.respSearch,
            };

        case "UPDATE_NOTIFICATIONS":
            return {
                ...state,
                notifications: action.notifications,
                notifications_count: action.notifications_count,
            };
        // Update RM session
        case "UPDATE_RM_SESSION":
            return {
                ...state,
                rm_session: action.rm_session,
            };
        case "SET_MESSAGES":
            return {
                ...state,
                message: action.message,
            };
        case "ADD_MESSAGE":
            let message = state.message;
            if (action.message?.message_type === "start_typing") {
                if (action.message?.contact !== state.rm_session.chat_profile) {
                    message.last_message = "typing...";
                    return {
                        ...state,
                        message: message,
                    };
                }
            } else if (action.message?.message_type === "start_recording") {
                if (action.message?.contact !== state.rm_session.chat_profile) {
                    message.last_message = "recording audio...";
                    return {
                        ...state,
                        message: message,
                    };
                }
            } else if (action.message?.message_type === "read_message") {
                if (action.message?.contact !== state.rm_session.chat_profile) {
                    message.unread_messages_count = 0;
                    return {
                        ...state,
                        message: message,
                    };
                }
            } else {
                message.last_message = action.message?.last_message;
                if (action.message?.message_type === "session_update") {
                    message.unread_messages_count =
                        action.message?.student_unread_messages_count;
                    return {
                        ...state,
                        message: message,
                    };
                }
            }

            return {
                ...state,
                message: message,
            };

        default:
            return state;
    }
};

const store = createStore(reducer);
export default store;
