// First we need to import axios.js
import axios from "axios";

// #### LIVE SERVER CONFIGS #### //
// export const chatBaseUrl = "wss://communication.steyp.com/";
// export const accountsConfig = axios.create({
//     baseURL: "https://accounts.talrop.com",
// });
// export const learnConfig = axios.create({
//     baseURL: "https://learn.talrop.com/api/v1",
// });
// export const notificationsConfig = axios.create({
//     baseURL: "https://notifications.talrop.com/api/v1",
// });
// export const coinsConfig = axios.create({
//     baseURL: "https://coins.talrop.com/api/v1",
// });
// export const webConfig = axios.create({
//     baseURL: "https://api-tce.talrop.com/api/v1/web",
// });
// export const communicationsConfig = axios.create({
//     baseURL: "https://communication.steyp.com/api/v1/",
// });
// export const communityConfig = axios.create({
//     baseURL: "https://community.talrop.com/api/v1/",
// });
// export const primeprogramsConfig = axios.create({
//     baseURL: "https://prime-programs.steyp.com/api/v1/",
// });
// export const challengeConfig = axios.create({
//     baseURL: "https://challenges.steyp.com/api/v1/",
// });

// #### DEVELOPER SERVER CONFIGS #### //
export const chatBaseUrl = "wss://developers-communication.talrop.com/";
export const baseUrl = axios.create({
	baseURL: "http://mishkath-beta.talrop.works/api/v1",
});
export const accountsConfig = axios.create({
	baseURL: "http://mishkath-accounts.talrop.works",
});
export const learnConfig = axios.create({
	baseURL: "http://mishkath-learn.talrop.works/api/v1",
});
export const notificationsConfig = axios.create({
	baseURL: "https://developers-notifications.talrop.com/api/v1",
});
export const coinsConfig = axios.create({
	baseURL: "http://mishkath-coins.talrop.works/api/v1",
});
export const webConfig = axios.create({
	baseURL: "https://developers-api-tce.talrop.com/api/v1/web",
});
export const communicationsConfig = axios.create({
	baseURL: "https://developers-communication.talrop.com/api/v1/",
});
export const communityConfig = axios.create({
	baseURL: "https://developers-community.talrop.com/api/v1/",
});
export const primeprogramsConfig = axios.create({
	baseURL: "https://demo-prime-programs.talrop.works/api/v1/",
});
export const challengeConfig = axios.create({
	baseURL: "https://developers-challenges.steyp.com/api/v1/",
});
