import React, { useEffect, useState } from "react";
import "./assets/css/LineAwesome.css";
import { useDispatch, useSelector } from "react-redux";
import { accountsConfig, notificationsConfig } from "./axiosConfig";
import AppRouter from "./components/routing/AppRouter";
import auth from "./components/routing/auth";
import RouteLoading from "./components/routing/RouteLoading";
import CacheBuster from "./CacheBuster";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-magic-slider-dots/dist/magic-dots.css";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";

const App = (props) => {
    const [isAppLoading, setLoading] = useState(true);

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        function updateDimensions() {
            let menu_type = "";
            if (window.innerWidth <= 768) {
                menu_type = "hidden";
            } else if (window.innerWidth <= 1500) {
                menu_type = "mini-sidebar";
            } else {
                menu_type = "normal";
            }
            dispatch({
                type: "MENU_TYPE",
                menu_type: menu_type,
            });
        }

        const fetchUserData = () => {
            let { search } = location;
            const values = queryString.parse(search);
            const access_token = values.t;
            if (values.t) {
                accountsConfig
                    .get(
                        "api/v1/users/get-student-info/",

                        {
                            headers: {
                                Authorization: `Bearer ${access_token}`,
                            },
                            params: {
                                service: "learn",
                            },
                        }
                    )
                    .then((response) => {
                        let { StatusCode, data } = response.data;
                        if (StatusCode === 6000) {
                            dispatch({
                                type: "UPDATE_USER_DATA",
                                user_data: data,
                            });
                            fetchUserProfileData(data);
                            history.push("/tech-schooling/");
                        } else if (StatusCode === 6001) {
                            history.push("/tech-schooling/");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                let user_data_stored = localStorage.getItem("user_data");
                let user_data_value = JSON.parse(user_data_stored);

                let signup_data_stored = localStorage.getItem("signup_data");
                if (!signup_data_stored) {
                    localStorage.setItem("signup_data", JSON.stringify({}));
                    signup_data_stored = localStorage.getItem("signup_data");
                }

                let signup_data_value = JSON.parse(signup_data_stored);
                dispatch({
                    type: "UPDATE_SIGNUP_DATA",
                    signup_data: signup_data_value,
                });
                fetchUserProfileData(user_data_value);
            }
        };
        fetchUserData();
        updateDimensions();
        window.addEventListener("resize", updateDimensions);

        return () => {
            window.removeEventListener("resize", updateDimensions);
        };
    }, []);

    // User data present in redux
    const user_data = useSelector((state) => state.user_data);
    const dispatch = useDispatch();

    const refreshToken = () => {
        let { access_token, refresh_token } = user_data;
        accountsConfig
            .post(
                "/api/v1/users/token/refresh/",
                {
                    service: "learn",
                    refresh_token: refresh_token,
                },
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                const { StatusCode, data } = response.data;

                if (StatusCode === 6000) {
                    if (data.error === "invalid_grant") {
                        localStorage.clear();
                        auth.logout(() => {
                            return true;
                        });
                        window.location = "";
                        setTimeout(() => {
                            setLoading(false);
                        }, 500);
                    } else {
                        dispatch({
                            type: "UPDATE_USER_DATA",
                            user_data: {
                                ...user_data,
                                access_token: "32ijz2OGEoIXV3wGmqY6mxiBl1Smri",
                                refresh_token: "aspKCIrDTwcv5OEeGE874ht5N3JxA1",
                            },
                        });
                        setTimeout(() => {
                            fetchUserProfileData();
                        }, 500);
                    }
                } else {
                    localStorage.clear();
                    window.location = "";
                    setTimeout(() => {
                        setLoading(false);
                    }, 500);
                }
            })
            .catch((error) => {
                localStorage.clear();
                window.location = "";
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            });
    };

    const fetchUserProfileData = (data) => {
        let user = data ? data : user_data;
        if (user) {
            if (user.is_verified) {
                auth.login(() => {
                    return true;
                });
            } else {
                auth.logout(() => {
                    return true;
                });
            }
        }
        if (user) {
            let { access_token, is_verified } = user;

            if (is_verified) {
                accountsConfig
                    .get("/api/v1/users/profile/", {
                        params: { response_type: "minimal" },
                        headers: { Authorization: `Bearer ${access_token}` },
                    })
                    .then((response) => {
                        const { StatusCode, data } = response.data;

                        if (StatusCode === 6000) {
                            let { subscription_data } = data;
                            if (subscription_data.has_active_subscription) {
                                dispatch({
                                    type: "UPDATE_USER_DATA",
                                    user_data: {
                                        ...user,
                                        has_active_subscription:
                                            subscription_data.has_active_subscription,
                                        name: data.name,
                                    },
                                });
                            }
                            dispatch({
                                type: "UPDATE_USER_PROFILE",
                                user_profile: data,
                            });
                            // fetchNotifications(access_token);
                            // initializeFirebase(access_token);

                            setLoading(false);
                        } else {
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                refreshToken();
                            } else {
                                setLoading(false);
                            }
                        } else {
                            setLoading(false);
                        }
                    });
            } else {
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    };

    const fetchNotifications = (access_token) => {
        notificationsConfig
            .get("main/user-notifications/", {
                params: { "response-length": 3 },
                headers: { Authorization: `Bearer ${access_token}` },
            })
            .then((response) => {
                const { StatusCode, data, count } = response.data;

                if (StatusCode === 6000) {
                    dispatch({
                        type: "UPDATE_NOTIFICATIONS",
                        notifications: data,
                        notifications_count: count,
                    });
                }
            })
            .catch((error) => {});
    };

    return (
        <CacheBuster>
            {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                if (loading) return null;
                if (!loading && !isLatestVersion) {
                    // You can decide how and when you want to force reload
                    refreshCacheAndReload();
                }

                return (
                    <React.Fragment>
                        {isAppLoading ? <RouteLoading /> : <AppRouter {...props} />}
                    </React.Fragment>
                );
            }}
        </CacheBuster>
    );
};

export default App;
